/* --Global-css----  */
.skeleton-container{
  height: 100%;
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(37,130,247,0.1393732492997199) 97%) ;
  min-height: calc(100vh - 160px);
}
.skeleton-container.podcast-skeleton{
 min-height: auto;
}
.skeleton-container .skeleton {
  border-radius: 4px;
    background: linear-gradient(
      90deg,
      #e0e0e0 25%,
      #f5f5f5 50%,
      #e0e0e0 75%
    );
    background-size: 200% 100%;
    animation: shimmer 1s infinite linear;
  }

@keyframes shimmer {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
  

/* ---banner-section---- */

.skeleton-container .text-banner-badge.hero {
  height: 24px;
  max-width: 200px;
  width: 100%;
}

.skeleton-container .main-heading.hero {
  height: 101px;
  max-width: 586px;
  width: 100%;
}
.skeleton-container .pera.hero {
  height: 130px;
  max-width: 586px;
  width: 100%;
}
.skeleton-container .btn-skeleton.hero {
  height: 34px;
  max-width: 200px;
  width: 100%;
}
.skeleton-container .play-skeleton.hero {
  height: 32px;
  display: inline-block;
  max-width: 32px;
  width: 100%;
  border-radius: 50%;
}
.skeleton-container .video.hero {
  height: 360px;
  max-width: 636px;
  width: 100%;
}
.skeleton-container .hero-btn-container {
  max-width: 145px;
  width: 100%;
}


/* ---service-detail-page---  */

.skeleton-container .sec_heading {
  max-width: 335px;
  height: 43px;
  margin-bottom: 20px;
  width: 100%;
}
.skeleton-container .Presents {
  max-width: 144px;
  height: 43px;
  width: 100%;
}
.skeleton-container .sec_heading-sub {
  max-width: 260px;
  height: 28px;
  width: 100%;
}

/* ----shop-detail-page-----  */

.skeleton-container .img-sub {
  max-width: 106px;
  height: 106px;
  height: 100%;
  width: 100%;
}
.skeleton-container .product-category {
  max-width: 260px;
  width: 100%;
}
.skeleton-container .product-rating {
  width: 100%;
  max-width: 80px;
  height: 15px;
}
.skeleton-container .prodcut-name {
  height: 245px;
  width: 100%;
}
.skeleton-container .btn {
 max-width: 150px;
 width: 100%;
 height: 34px;
}
.skeleton-container .product-count {
 max-width: 134px;
 width: 100%;
 height: 40px;
 border: none;
}
.skeleton-container .product-prize {
 max-width: 80px;
 width: 100%;
 height: 26px;
}
.skeleton-container .video-thumbnail {
 max-width: 261px;
 width: 100%;
 aspect-ratio: 2/1;
}

/* ---blog-details-page---  */

.skeleton-container .details{
  height: 26px;
  max-width: 300px;
  margin: auto;
  margin-bottom: 30px;
  width: 100%;
}
.skeleton-container .sec_heading-stylish{
  height: 62px;
  max-width: 800px;
  margin: auto;
  margin-bottom: 20px;
  width: 100%;
}

.skeleton-container .social{
  height: 24px;
  max-width: 200px;
  margin: auto;
  width: 100%;
}
.skeleton-container .blog-feature-img{
  height: 100vh;
  margin: auto;
  width: 100%;
  max-height: 400px;
}

/* ----shop-page---  */

.skeleton-container .category-item{
  width: 100%;
  max-width: 305px;
  min-height: 36px;
}
.skeleton-container .product-img{
  width: 100%;
  max-width: 100%;
  height: 200px;
}
.skeleton-container .searchbar{
  width: 100%;
  max-width: 245px;
  min-height: 45px;
  border-radius: 25px;
}
.skeleton-container .product-price{
  width: 100%;
  max-width: 50px;
  min-height: 22px;
}

/* ----Shape-Shifting-Mastery---  */

.welcome-top.skeleton-container .title{
  width: 100%;
  max-width: 900px;
  min-height: 116px;
} 
.welcome-top.skeleton-container .sub_heading{
  width: 100%;
  max-width: 1000px;
  min-height: 43px;
} 
.welcome-top.skeleton-container .Presents{
  width: 100%;
  max-width: 200px;
  min-height: 31px;
} 
.welcome-top.skeleton-container .hosted-logo{
  width: 200px;
  min-height: 50px;
} 
.welcome-top.skeleton-container .pera{
  width: 100%;
  max-width: 307px;
  min-height: 50px;
} 
.welcome-top.skeleton-container .hosted-content{
  width: 100%;
  max-width: 570px;
  min-height: 50px;
} 

/* ----sercice-detail---  */

.service-detail.skeleton-container .sub_heading{
  width: 100%;
  max-width: 335px;
  min-height: 43px;
}
/* ----podcast-detail---  */

.podcast-detail.skeleton-container .sec_heading{
  width: 100%;
  max-width: 800px;
  min-height: 43px;
}

.podcast-detail.skeleton-container .pera{
  width: 100%;
  max-width: 1000px;
  min-height: 78px;
}
.podcast-detail.skeleton-container .detail-podcast-video{
  width: 100%;
  max-width: 700px;
  aspect-ratio: 16/9;
  min-height: 200px;
}

/* ----user-profile-skeleton------  */

.skeleton-container.skeleton-container-user{
  min-height: auto;
  height: auto;
  background: #fff;
}

.skeleton-container-user .avatar-upload{
  width: 100%;
  max-width: 118px;
  border-radius: 50%;
  min-height: 118px;
}
.skeleton-container-user .profile-sec_heading{
  width: 100%;
  max-width: 58px;
  min-height: 24px;
}
.skeleton-container-user .edit{
  width: 100%;
  max-width: 14px;
  min-height: 14px;
}
.skeleton-container-user li.data-user:nth-child(odd){
  width: 100%;
  max-width: 200px;
  min-height: 22px;
}
.skeleton-container-user li.data-user:nth-child(even){
  width: 100%;
  max-width: 250px;
  min-height: 24px;
}
.skeleton-container-user .user-name{
  width: 100%;
  max-width: 126px;
  min-height: 48px;
}

/* ----Plan-skeleton------  */

.skeleton-container.skeleton-plan{
  min-height: auto;
  height: auto;
  background: #fff;
}
.skeleton-plan .heading{
  width: 100%;
  max-width: 126px;
  min-height: 25px;
}
.skeleton-plan .sub-heading{
  width: 100%;
  max-width: 80px;
  min-height: 30px;
}
.skeleton-plan .text{
  width: 100%;
  max-width: 300px;
  min-height: 26px;
}

/* -----sleleton-adress--  */

.skeleton-container.skeleton-address{
  min-height: auto;
  height: auto;
  background: #fff;
}
.skeleton-address .heading{
  width: 100%;
  max-width: 126px;
  min-height: 25px;
}
.skeleton-address .form-label{
  width: 100%;
  max-width: 70px;
  min-height: 19.59px;
}
.skeleton-address .input{
  width: 100%;
  min-height: 25px;
}
.skeleton-address .form-check-label{
  width: 100%;
  max-width: 308px;
  min-height: 19.59px;
}
.skeleton-address .input-checkbox{
  width: 16px;
  height: 16px;  
  position: absolute;
  left: 0;
  top: 2px;
  display: inline-block;
}

/* ---Success Stories---  */

.success-stories.skeleton-container .main-heading{
  width: 100%;
  margin: auto;
  max-width: 683px;
  min-height: 50.39px;
} 
.success-stories.skeleton-container .text-banner-badge{
  width: 100%;
  max-width: 111px;
  margin: auto;
  margin-bottom: .5rem;
  min-height: 24px;
} 
.success-stories.skeleton-container .pera{
  width: 100%;
  max-width: 100%;
  margin: auto;
  min-height: 104px;
  margin-bottom: 10px;
} 
.success-stories.skeleton-container{
  min-height: calc(100vh - 434.34px)
} 

/* ----checkout-page-order-summary-------  */

.skeleton-container.order-summary .summry-content li.item{
width: 100%;
height: 24px;
margin-bottom: 4px;
max-width: 300px;
}
.skeleton-container.payment-detail .input.cv{
max-width: 80px;
margin-left: auto;
}




